/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap-datepicker@1.8.0/dist/css/bootstrap-datepicker.min.css
 * - /npm/angular-toastr@1.5.0/dist/angular-toastr.min.css
 * - /npm/daterangepicker@3.0.5/daterangepicker.min.css
 * - /npm/angular-joyride@1.0.2/dist/joyride.min.css
 * - /npm/ng-dialog@1.4.0/css/ngDialog.min.css
 * - /npm/ng-dialog@1.4.0/css/ngDialog-theme-default.min.css
 * - /npm/webui-popover@1.2.18/dist/jquery.webui-popover.min.css
 * - /npm/trix@0.12.0/dist/trix.min.css
 * - /npm/angucomplete-alt@3.0.0/angucomplete-alt.min.css
 * - /npm/pivottable@2.23.0/dist/pivot.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
